import { lazy } from 'react';

import NotFound from '~shared/components/ErrorPage/NotFound';

import { useCashConfiguration } from './utils';

const WalletFinanceStorePage = lazy(() => import('./WalletFinanceStorePage'));
const WalletFinanceCourierPage = lazy(() => import('./WalletFinanceCourierPage'));

export const WalletStorePage = () => {
	const cashConfig = useCashConfiguration();

	if (!cashConfig.isConfigsReady) {
		return null;
	}

	if (cashConfig.isNewWalletUI) {
		return <WalletFinanceStorePage cashConfig={cashConfig} />;
	}

	return <NotFound />;
};

export const WalletCourierPage = () => {
	const cashConfig = useCashConfiguration();

	if (!cashConfig.isConfigsReady) {
		return null;
	}

	if (cashConfig.isNewWalletUI) {
		return <WalletFinanceCourierPage cashConfig={cashConfig} />;
	}

	return <NotFound />;
};
