export const getColumns = (columns: string[]) =>
	columns.reduce(
		(obj, column) => {
			obj[column] = column;
			return obj;
		},
		{} as Record<string, string>
	);

export const configTemplateColumns = ['external_id', 'enabled'];
export const assortmentTemplateColumns = ['external_id'];
export const sampleTemplateColumns = ['external_id'];
export const topologyTemplateColumns = [
	'rack',
	'shelf',
	'order',
	'tag',
	'barcode',
	'type',
	'status',
	'width',
	'height',
	'depth',
	'shelf_id',
	'warehouse_group',
	'external_id',
];
export const courierTemplateColumns = [
	'wms_cluster_id',
	'first_name',
	'last_name',
	'middle_name',
	'gender',
	'birthday',
	'phone',
	'citizenship',
	'wms_courier_service_id',
	'courier_transport_type',
];
export const slotTemplateColumns = [
	'region_id',
	'region_name',
	'depot_id',
	'area_name',
	'date',
	'weekday',
	'start',
	'duration',
	'number_of_couriers',
	'auto',
	'guarantee',
	'public',
	'is_auto_offered',
];
export const adjustmentTemplateColumns = [
	'assigned_at',
	'reason_slug',
	'link_id',
	'courier_id',
	'order_number',
	'amount',
	'comment',
];
export const priceListProductsTemplateColumns = ['default_code', 'store_price', 'price_per_count'];
